import { IInitialUserState } from '@/types/user.type';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: IInitialUserState = {
  isAuthorized: false,
  token: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isAuthorized = true;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.isAuthorized = false;
    },
  },
});

export const { login, logout, setToken } = authSlice.actions;
export default authSlice.reducer;
