import { Button, DatePicker, ToggleSwitch } from '@/components';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Plot from 'react-plotly.js';
import * as Styled from './style';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { RelaySessionData } from '@/types/report.type';

interface LeakMapperProps {
  relaySessionData: RelaySessionData;
}

const LeakMapper: FC<LeakMapperProps> = ({ relaySessionData }) => {
  const [graphType, setGraphType] = useState<'old' | 'new'>('new');

  const { t, prefixedT } = useCustomTranslation(
    'SESSION_PAGE.ENGINEERING_DETAILS',
  );

  const dataLength = relaySessionData?.audio_data.power.length || 0;

  const minDate = useMemo(() => {
    return moment(relaySessionData?.audio_data.date[0], 'YYYY-MM-DD').toDate();
  }, [relaySessionData]);

  const maxDate = useMemo(() => {
    return moment(
      relaySessionData?.audio_data.date[dataLength - 1],
      'YYYY-MM-DD',
    ).toDate();
  }, [relaySessionData]);

  // for plotting seperate surfaces for each date
  const plotData: Plotly.Data[] = useMemo(() => {
    return new Array(dataLength).fill({}).map((_, idx) => {
      const startIdx = idx * 5;
      const endIdx = (idx + 1) * 5;

      return {
        x: relaySessionData?.audio_data.frequency.slice(startIdx, endIdx),
        y: relaySessionData?.audio_data.date
          .map((date) => new Date(date))
          .slice(startIdx, endIdx),
        z: relaySessionData?.audio_data.power.slice(startIdx, endIdx),
        type: 'surface',
        colorscale: [
          [0, 'rgb(166,206,227)'],
          [0.25, 'rgb(31,120,180)'],
          [0.45, 'rgb(178,223,138)'],
          [0.65, 'rgb(51,160,44)'],
          [0.85, 'rgb(251,154,153)'],
          [1, 'rgb(227,26,28)'],
        ],
        showscale: false,
      };
    });
  }, [relaySessionData, dataLength]);

  const [dateIdx, setDateIdx] = useState({ start: 0, end: dataLength / 5 });

  useEffect(() => {
    setDateIdx({ start: 0, end: dataLength / 5 });
  }, [dataLength]);

  return (
    <Styled.GraphWrapper data-testid={'relay-plot'}>
      <Styled.ControlsContainer>
        <Styled.ToggleContainer>
          <p>{prefixedT('3D_PLOT_TOGGLE_TITLE')}</p>
          <ToggleSwitch
            isChecked={graphType === 'new'}
            idPrefix={'graphType'}
            onToggle={() => setGraphType(graphType === 'old' ? 'new' : 'old')}
          />
        </Styled.ToggleContainer>

        <Formik
          enableReinitialize
          initialValues={{ mindate: minDate, maxdate: maxDate }}
          onSubmit={(values: { mindate: Date; maxdate: Date }) => {
            const formattedMinDate = moment(values.mindate);
            const formattedMaxDate = moment(values.maxdate);

            const minDateIndex = relaySessionData.audio_data.date.findIndex(
              (date) => moment(date) > formattedMinDate,
            );
            const minDateIndexAfterSelected =
              minDateIndex !== -1 ? minDateIndex : 0;

            const maxDateIndex = relaySessionData.audio_data.date.findIndex(
              (date) => moment(date) > formattedMaxDate,
            );
            const maxDateIndexAfterSelected =
              maxDateIndex !== -1
                ? maxDateIndex
                : relaySessionData.audio_data.date.length;

            if (minDateIndexAfterSelected <= maxDateIndexAfterSelected) {
              setDateIdx({
                start: minDateIndexAfterSelected / 5,
                end: maxDateIndexAfterSelected / 5 + 1,
              });
            }
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <Styled.Row>
                <Field
                  as={DatePicker}
                  name="mindate"
                  label={t('COMPONENTS.DATE_VIEWER.START_DATE')}
                  onChange={(date: Date) => setFieldValue('mindate', date)}
                  maxDate={maxDate}
                  disableFuture
                  minDate={minDate}
                  disablePast
                  width="100%"
                />
                <Field
                  as={DatePicker}
                  name="maxdate"
                  label={t('COMPONENTS.DATE_VIEWER.END_DATE')}
                  onChange={(date: Date) => setFieldValue('maxdate', date)}
                  maxDate={maxDate}
                  disableFuture
                  minDate={minDate}
                  disablePast
                  width="100%"
                />
              </Styled.Row>
              <Styled.Row>
                <Button type="submit" width="200px" height="50px">
                  {prefixedT('APPLY_DATES')}
                </Button>
                <Button
                  width="200px"
                  height="50px"
                  onClick={() => {
                    void (async () => {
                      setDateIdx({ start: 0, end: dataLength / 5 });
                      await Promise.all([
                        setFieldValue('mindate', minDate),
                        setFieldValue('maxdate', maxDate),
                      ]);
                    })();
                  }}
                >
                  {prefixedT('RESET_DATES')}
                </Button>
              </Styled.Row>
            </Form>
          )}
        </Formik>
      </Styled.ControlsContainer>
      <Styled.RelayGraphContainer>
        <Plot
          data={
            graphType === 'old'
              ? [
                  {
                    x: relaySessionData.audio_data.frequency.slice(
                      dateIdx.start * 5,
                      dateIdx.end * 5,
                    ),
                    y: relaySessionData.audio_data.date.slice(
                      dateIdx.start * 5,
                      dateIdx.end * 5,
                    ),
                    z: relaySessionData.audio_data.power.slice(
                      dateIdx.start * 5,
                      dateIdx.end * 5,
                    ),
                    type: 'surface',
                    colorscale: [
                      [0, 'rgb(166,206,227)'],
                      [0.25, 'rgb(31,120,180)'],
                      [0.45, 'rgb(178,223,138)'],
                      [0.65, 'rgb(51,160,44)'],
                      [0.85, 'rgb(251,154,153)'],
                      [1, 'rgb(227,26,28)'],
                    ],
                    showscale: false,
                  },
                ]
              : plotData.slice(dateIdx.start, dateIdx.end)
          }
          layout={{
            autosize: true,
            height: 900,
            title: 'Leakmapper',
            scene: {
              xaxis: { title: t('SESSION_PAGE.RELAY_GRAPH.X') },
              yaxis: {
                title: t('SESSION_PAGE.RELAY_GRAPH.Y'),
                tickwidth: 2,
              },
              zaxis: { title: t('SESSION_PAGE.RELAY_GRAPH.Z') },
            },
          }}
        />
      </Styled.RelayGraphContainer>
    </Styled.GraphWrapper>
  );
};

export default LeakMapper;
