import { Asset, DMAs, Pipes } from '@/assets/icons/filterIcons';
import CheckboxWithIcon from '@/components/CheckboxWithIcon/CheckboxWithIcon';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { FilterContext, FilterName } from '@/Providers/FilterProvider';
import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import MapViewContext from '../../context/MapViewContext';
import {
  marker,
  sessionCyan,
  sessionGreen,
  sessionOrange,
  sessionRed,
  sessionPurple,
  sessionYellow,
  odl,
} from '../../geospatialFeatures/assets';
import { Button } from '../../style';
import * as Styled from './style';

type ImageMap = {
  [K in FilterName]: string;
};

const FilterForm = () => {
  const { t, prefixedT } = useCustomTranslation('MAP_VIEW.FILTERS.TITLES');
  const { mapContainerWidth, onSubmit } = useContext(MapViewContext);
  const { mapFilters, filterMapValues } = useContext(FilterContext);

  const initValues =
    mapFilters.filters.length === 0 ? { filters: ['DMAS'] } : mapFilters;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initValues}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ values }) => (
          <Form>
            <Styled.CheckboxContainer mapContainerWidth={mapContainerWidth}>
              {filterMapValues.map((value: FilterName, index: number) => {
                const imageMap: ImageMap = {
                  WAYPOINTS: marker,
                  DMAS: DMAs,
                  PIPES: Pipes,
                  ASSETS: Asset,
                  IN_PROGRESS_SESSION: sessionPurple,
                  AWAITING_UPLOAD_SESSION: sessionCyan,
                  LARGE_LEAK_SESSION: sessionRed,
                  MEDIUM_LEAK_SESSION: sessionOrange,
                  SMALL_LEAK_SESSION: sessionYellow,
                  NON_LEAK_SESSION: sessionGreen,
                  ODL: odl,
                };
                const imageSrc = imageMap[value];

                return (
                  <CheckboxWithIcon
                    key={index}
                    mapContainerWidth={mapContainerWidth}
                    formName={'filters'}
                    label={prefixedT(value)}
                    image={imageSrc}
                  />
                );
              })}
            </Styled.CheckboxContainer>

            <Styled.BtnContainer>
              <Button
                type="submit"
                disabled={values.filters.length < 1}
                // @ts-expect-error fix later
                width="200px"
                height="50px"
              >
                {t('MAP_VIEW.FILTERS.SUBMIT')}
              </Button>
            </Styled.BtnContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FilterForm;
