import {
  Feature,
  Geometry,
  GeoJsonProperties,
  FeatureCollection,
  Point,
} from 'geojson';
import mapbox, { Map } from 'mapbox-gl';

export const layerID = 'unclustered-point';
export const clusterLayerID = 'clusters';
interface ExtendedGeoJSONSource extends mapboxgl.GeoJSONSource {
  _data?: {
    features: Feature<Point, GeoJsonProperties>[];
  };
}

export const getSourceData = (
  map: Map,
): Feature<Point, GeoJsonProperties>[] => {
  const source = map.getSource(clusterLayerID) as ExtendedGeoJSONSource;
  if (!source || !source._data || !source._data.features) {
    throw new Error('Source data or features are missing.');
  }
  return source._data.features;
};

export const createOrUpdateSource = (
  map: Map,
  combinedFeatures: Feature<Geometry, GeoJsonProperties>[],
) => {
  const setConfig: FeatureCollection = {
    type: 'FeatureCollection',
    features: combinedFeatures,
  };

  const existingSource = map.getSource(
    clusterLayerID,
  ) as mapboxgl.GeoJSONSource;

  if (existingSource) {
    existingSource.setData(setConfig);
  } else {
    const sourceConfig: mapbox.GeoJSONSourceRaw = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: combinedFeatures,
      },
      cluster: true,
      clusterMaxZoom: 13,
      clusterRadius: 50,
    };

    map.addSource(clusterLayerID, sourceConfig);
    map.addSource('bufferedPolygonSource', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
    map.addLayer({
      id: 'bufferedPolygonLayer',
      source: 'bufferedPolygonSource',
      type: 'fill',
      paint: {
        'fill-color': '#ff0000',
        'fill-opacity': 0.5,
      },
    });
  }
};

export const createClusterlayer = (map: Map) => {
  if (!map.getLayer(clusterLayerID)) {
    map.addLayer({
      id: clusterLayerID,
      type: 'circle',
      source: clusterLayerID,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          100,
          '#f1f075',
          750,
          '#f28cb1',
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      },
    });
  }

  const clusterCountID = 'cluster-count';
  if (!map.getLayer(clusterCountID)) {
    map.addLayer({
      id: clusterCountID,
      type: 'symbol',
      source: clusterLayerID,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
    });
  }
};

export const sessions = [
  'small_leak_session',
  'medium_leak_session',
  'large_leak_session',
  'non_leak_session',
  'in_progress_session',
  'awaiting_upload_session',
];

export const relaySessions = [
  'small_relay_session',
  'medium_relay_session',
  'large_relay_session',
  'non_leak_relay_session',
  'in_progress_relay_session',
  'awaiting_upload_relay_session',
];

export const assets = [
  'valve',
  'hydrant',
  'marker',
  'pmv',
  'fitting',
  'tapping',
  'logger',
  'unknown',
  'pump',
  'meter',
  'connection',
  'Feature',
];
