import { InitialRequestState } from '@/types/request.type';
import { IInitialUserState } from '@/types/user.type';
import { getApiEntities } from '@/utils/redux';
import {
  Reducer,
  StoreEnhancer,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import config from '../config';
import activeAccount, {
  ActiveAccountState,
} from './activeAccount/activeAccount.slice';
import { aiAssistantApi } from './aiAssistant/aiAssistant.api';
import { authorizationApi } from './authorization/authorization.api';
import auth from './authorization/authorization.slice';
import { branchApi } from './branches/branches.api';
import { genericFetchApi } from './genericFetch/genericFetch.api';
import header, { HeaderState } from './header/header.slice';
import { highlandPortalApi } from './highlandPortal/highlandPortal.api';
import { logApi } from './log/log.api';
import { mobileAppApi } from './mobileApp/mobileApp.api';
import { portalCompaniesApi } from './portalCompanies/portalCompanies.api';
import { portalPartnersApi } from './portalPartners/portalPartners.api';
import { portalProjectsApi } from './portalProjects/portalProjects.api';
import { portalUsersApi } from './portalUsers/portalUsers.api';
import { reportApi } from './reports/reports.api';
import request from './request/request.slice';
import { userApi } from './user/user.api';
import * as Sentry from '@sentry/react';

export interface RootState {
  auth: IInitialUserState;
  request: InitialRequestState;
  activeAccount: ActiveAccountState;
  header: HeaderState;
}

const apiEntities = [
  userApi,
  authorizationApi,
  aiAssistantApi,
  branchApi,
  genericFetchApi,
  logApi,
  reportApi,
  portalCompaniesApi,
  mobileAppApi,
  portalProjectsApi,
  portalPartnersApi,
  highlandPortalApi,
  portalUsersApi,
];

const sliceEntities = { auth, request, activeAccount, header };

const { reducers, middlewares } = getApiEntities(apiEntities);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['activeAccount', 'auth'],
};

const RESET_STATE = 'RESET_STATE';

export const resetState = () => ({
  type: RESET_STATE,
});

interface Action<T = unknown> {
  type: string;
  payload?: T;
}

const rootReducer: Reducer<RootState, Action> = (
  state: RootState | undefined,
  action: Action,
) => {
  if (action.type === RESET_STATE) {
    state = undefined;
  }

  return combineReducers({ ...sliceEntities, ...reducers })(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sentryReduxEnhancer = Sentry.createReduxEnhancer() as StoreEnhancer;

export const store = configureStore({
  reducer: persistedReducer,
  devTools: config.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => {
    const defaultMiddlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
        warnAfter: 240,
      },
    });

    return [...defaultMiddlewares, ...middlewares];
  },
  enhancers: [sentryReduxEnhancer],
});

export const persistor = persistStore(store);
