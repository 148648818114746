export interface IInitialUserState {
  isAuthorized: boolean;
  token: string;
}

export enum UserStatus {
  active = 'active',
  pending = 'pending',
}

export enum UserRoles {
  SUPER_ADMIN = 'SAD',
  PARTNER_ADMIN = 'PAD',
  PARTNER_USER = 'PUS',
  PARTNER_LEAK_ENGINEER = 'PLE',
  PARTNER_BUG_DEPLOYMENT_ENGINEER = 'PBDE',
  COMPANY_ADMIN = 'CAD',
  COMPANY_USER = 'CUS',
  COMPANY_LEAK_ENGINEER = 'CLE',
  COMPANY_BUG_DEPLOYMENT_ENGINEER = 'CBDE',
}

export interface IRole {
  role_name: string;
  role_code: UserRoles;
  permissions_role?: PermissionRole[];
}

export interface Login {
  access_token: string;
  token_type: string;
}

export interface IUser {
  first_name: string;
  last_name: string;
  status: UserStatus;
  active: boolean;
  id?: string | number;
  email?: string;
  phone?: string;
  role_id?: number;
  partner_id?: number;
  company_id?: number;
  when_added?: string;
  last_modified?: string;
  metric_system?: string;
  role: IRole;
  company?: null | CompanyDetails;
  partner?: null | PartnerDetails;
}

export interface PartnerDetails {
  id?: number;
  partner_name?: string;
  partner_address?: string;
  partner_code?: string;
  active?: boolean;
  logo?: string;
  currency?: string;
  country_code?: string;
}
export interface CompanyDetails {
  company_name?: string | null;
  address?: string;
  phone?: string;
  language?: string;
  metric_system?: string;
  currency?: string;
  company_code?: string;
  country_code?: string;
  active?: boolean;
  partner_id?: number;
  logo?: string;
  cost_per_ml?: number;
  carbon_g_per_ml?: number;
  cost_per_ml_sell?: number;
  id?: number;
}
interface PermissionRole {
  id: number;
  permission: string;
}

export interface CreateUserValues {
  firstName: string;
  lastName: string;
  companyId: number | null;
  branchId: number | null;
  roleId: number;
  email: string;
  redirectUrl: string;
}

export interface EditUserValues {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  metric_system: string;
  companyId?: string | number | null;
  branchId?: string | number | null;
  roleId?: string | number;
}

export interface EditUserBody {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  metric_system?: string;
  role_id?: string | number | null;
  partner_id?: string | number | null;
  company_id?: string | number | null;
  userapp_id?: string;
  status?: number[];
}
