import { IAnimationProps, IRectConfig } from '@/types/common.type';
import React from 'react';
import { FC } from 'react';
import { createPortal } from 'react-dom';
import { DropDownOption } from './DropDown';
import * as Styled from './style';

interface IDropDownOptions {
  open: boolean;
  countOptions: number;
  isOverflow: boolean;
  animationProps: IAnimationProps;
  options: DropDownOption[];
  onSelect: (option: DropDownOption) => void;
  value: string | string[];
  multiple?: boolean;
  rectConfig: IRectConfig | undefined;
  listRef: React.RefObject<HTMLUListElement>;
  isPortal: boolean;
}

const DropDownList: FC<IDropDownOptions> = ({
  open,
  countOptions,
  isOverflow,
  isPortal,
  animationProps,
  options,
  onSelect,
  value,
  multiple,
  rectConfig,
  listRef,
}) => {
  const OptionsList = (
    <Styled.OptionsList
      config={{
        countOptions,
        isOverflow,
        top: rectConfig?.top || 0,
        left: rectConfig?.left || 0,
        width: rectConfig?.width || 0,
        isPortal,
      }}
      data-testid="dropdown-options"
      {...animationProps}
      open={open}
      ref={listRef}
    >
      {options.map((option: DropDownOption) => (
        <Styled.OptionItem
          data-testid="dropdown-option-item"
          onClick={onSelect.bind(null, option)}
          key={option.value}
          selected={option.value === value}
          multiple={multiple}
        >
          {multiple && (
            <Styled.Checkbox
              type="checkbox"
              readOnly
              checked={((value as string[]) || []).includes(option.value)}
            />
          )}
          {option.label}
        </Styled.OptionItem>
      ))}
    </Styled.OptionsList>
  );

  return (
    (open &&
      (!isPortal ? OptionsList : createPortal(OptionsList, document.body))) ||
    null
  );
};

export default DropDownList || {};
