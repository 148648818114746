import React from 'react';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { PointTooltipType } from '../../../utils/types';
import * as Styled from './style';

export const StandardTooltip = (values: PointTooltipType) => {
  const { prefixedT } = useCustomTranslation('TOOLTIP');

  return (
    <Styled.ToolTipContainer>
      {values.Address && (
        <Styled.Title>
          <span>{prefixedT('ADDRESS')}: </span>
          {values.Address}
        </Styled.Title>
      )}
      {values.Dma_id && (
        <Styled.Title>
          <span>{prefixedT('DMA_ID')}: </span>
          {values.Dma_id}
        </Styled.Title>
      )}
      {values.Deployment_order && (
        <Styled.Title>
          <span>{prefixedT('DEPLOYMENT_ORDER')}: </span>
          {values.Deployment_order}
        </Styled.Title>
      )}
      {values.Asset_key && (
        <Styled.Title>
          <span>{prefixedT('ASSET_KEY')}: </span>
          {values.Asset_key}
        </Styled.Title>
      )}
      {values.Diameter_mm && (
        <Styled.Title>
          <span>{prefixedT('DIAMETER')}: </span>
          {values.Diameter_mm}mm
        </Styled.Title>
      )}
      {values.Engineer && (
        <Styled.Title>
          <span>{prefixedT('ENGINEER')}: </span>
          {values.Engineer}
        </Styled.Title>
      )}
      {values.Standarised_asset_type && (
        <Styled.Title>
          <span>{prefixedT('STANDARISED_ASSET_TYPE')}: </span>
          {values.Standarised_asset_type}
        </Styled.Title>
      )}
      {values.Deployed_by && (
        <Styled.Title>
          <span>{prefixedT('DEPLOYED_BY')}: </span>
          {values.Deployed_by}
        </Styled.Title>
      )}
      {values.Waypoint_id && (
        <Styled.Title>
          <span>{prefixedT('WAYPOINT_ID')}: </span>
          {values.Waypoint_id}
        </Styled.Title>
      )}
      {values.Waypoint_name && (
        <Styled.Title>
          <span>{prefixedT('WAYPOINT_NAME')}: </span>
          {values.Waypoint_name}
        </Styled.Title>
      )}
      {values.Fido_result && (
        <Styled.Title>
          <span>{prefixedT('FIDO_RESULT')}: </span>
          {values.Fido_result}
        </Styled.Title>
      )}
      {values.Latitude && (
        <Styled.Title>
          <span>{prefixedT('LATITUDE')}: </span>
          {values.Latitude}
        </Styled.Title>
      )}
      {values.Longitude && (
        <Styled.Title>
          <span>{prefixedT('LONGITUDE')}: </span>
          {values.Longitude}
        </Styled.Title>
      )}
      {values.Leak_size && (
        <Styled.Title>
          <span>{prefixedT('LEAK_SIZE')}: </span>
          {values.Leak_size}
        </Styled.Title>
      )}
      {values.Session_id && (
        <Styled.Title>
          <span>{prefixedT('SESSION_ID')}: </span>
          {values.Session_id}
        </Styled.Title>
      )}
      {values.W3W && (
        <Styled.Title>
          <span>{prefixedT('WHAT_3_WORDS')}: </span>
          {values.W3W}
        </Styled.Title>
      )}
      {values.days_from_last_update && (
        <Styled.Title>
          <span>{prefixedT('DAYS_SINCE_LAST')}: </span>
          {values.days_from_last_update}
        </Styled.Title>
      )}
      {values.Material && (
        <Styled.Title>
          <span>{prefixedT('MATERIAL')}: </span>
          {values.Material}
        </Styled.Title>
      )}
      {values.standardized_material && (
        <Styled.Title>
          <span>{prefixedT('STANDARDISED_MATERIAL')}: </span>
          {values.standardized_material}
        </Styled.Title>
      )}
      {values.pipe_type && (
        <Styled.Title>
          <span>{prefixedT('PIPE_TYPE')}: </span>
          {values.pipe_type}
        </Styled.Title>
      )}
    </Styled.ToolTipContainer>
  );
};
